import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bp-video-menu-item" }
const _hoisted_2 = {
  key: 0,
  open: ""
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "bp-video-menu-item__name-wrapper" }
const _hoisted_6 = { class: "bp-video-menu-item__name" }
const _hoisted_7 = { class: "bp-video-menu-item__count" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "bp-video-menu-item__name-wrapper" }
const _hoisted_10 = { class: "bp-video-menu-item__name" }
const _hoisted_11 = { class: "bp-video-menu-item__count" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_video_menu_item = _resolveComponent("bp-video-menu-item", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: category._id
      }, [
        (_ctx.store.hasChildren(category._id, 'category'))
          ? (_openBlock(), _createElementBlock("details", _hoisted_2, [
              _createElementVNode("summary", {
                class: "bp-video-menu-item__summary",
                onClick: ($event: any) => (_ctx.toggle(category._id))
              }, [
                _createVNode(_component_bp_icon, {
                  class: "bp-video-menu-item__marker",
                  icon: "angle-right"
                }),
                _createElementVNode("div", {
                  class: _normalizeClass(['bp-video-menu-item__item', {
              'bp-video-menu-item__item--highlighted': category._id === _ctx.filter,
            }]),
                  onClick: _withModifiers(($event: any) => (_ctx.updateFilter(category._id)), ["stop","prevent"])
                }, [
                  _createElementVNode("span", _hoisted_5, [
                    _createVNode(_component_bp_icon, {
                      class: "bp-video-menu-item__icon",
                      icon: _ctx.folderIcon(category._id)
                    }, null, 8, ["icon"]),
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getTranslated(category.name)), 1)
                  ]),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.videoCount(category)), 1)
                ], 10, _hoisted_4)
              ], 8, _hoisted_3),
              _createVNode(_component_bp_video_menu_item, {
                class: "bp-video-menu-item__children",
                data: category._children,
                filter: _ctx.filter,
                search: _ctx.search,
                "onUpdate:filter": _ctx.updateFilter
              }, null, 8, ["data", "filter", "search", "onUpdate:filter"])
            ]))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(['bp-video-menu-item__item', 'bp-video-menu-item__item--no-children', {
          'bp-video-menu-item__item--highlighted': category._id === _ctx.filter,
        }]),
              onClick: ($event: any) => (_ctx.updateFilter(category._id))
            }, [
              _createElementVNode("span", _hoisted_9, [
                _createVNode(_component_bp_icon, {
                  class: "bp-video-menu-item__icon",
                  icon: ['far', 'folder']
                }),
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.getTranslated(category.name)), 1)
              ]),
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.videoCount(category)), 1)
            ], 10, _hoisted_8))
      ], 64))
    }), 128))
  ]))
}

import { Breakpoint, useMedia } from '@/utils/responsive-breakpoints';
import { computed, defineComponent, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import { currentLanguageISOString, getTranslated } from '@/translation';
import { escapeRegExp, formatBytes, base64ToHex } from '@/utils/string';
import { localizeDate } from '@/utils/date';
import { useRouter } from 'vue-router';
import { Video, useVideoStore, Recording, Webinar } from '@/stores/video';
import BpVideoMenuItemVue from '@/components/video/BpVideoMenuItem.vue';
import BpVideoPreviewVue from '@/components/video/BpVideoPreview.vue';
import BpMasonryGrid from '@/components/BpMasonryGrid.vue';

export default defineComponent({
  name: 'video-area',
  components: {
    BpVideoMenuItem: BpVideoMenuItemVue,
    BpVideoPreview: BpVideoPreviewVue,
  },
  setup() {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();

    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const store = useVideoStore(true);

    ///-------------------------------------------------------------------
    /// FILTER
    ///-------------------------------------------------------------------

    const filter = ref('all');

    function updateFilter(folderId: string) {
      filter.value = folderId;
    }

    const contentEl = ref<typeof BpMasonryGrid | HTMLDivElement>();

    watch(() => filter.value, scrollToContent);

    function scrollToContent() {
      const layout = document.getElementById('bp-layout');
      if (!layout) {
        return;
      }
      window.setTimeout(() => {
        if (!contentEl.value) {
          return;
        }
        const headerHeight = parseFloat(window.getComputedStyle(layout).getPropertyValue('--portal-header-height'));
        const { top } = contentEl.value instanceof HTMLDivElement ? contentEl.value.getBoundingClientRect() : contentEl.value.$el.getBoundingClientRect();
        window.scrollTo({ left: 0, top: window.scrollY + top - headerHeight, behavior: 'smooth' })
      }, 0)
    }

    ///-------------------------------------------------------------------
    /// SEARCH
    ///-------------------------------------------------------------------

    const search = ref((router.currentRoute.value.query.search as string) || '');

    ///-------------------------------------------------------------------
    /// GRID
    ///-------------------------------------------------------------------

    const masonry = ref(false);

    ///-------------------------------------------------------------------
    /// VIDEOS
    /// TODO: Discuss about adding active webinars to the videos
    ///-------------------------------------------------------------------

    const videos = computed(() => {
      const regex = new RegExp(escapeRegExp(search.value.trim()), 'i');
      return (
        filter.value === 'all'
        ? store.getVideos({ filter: ['featured-webinar', 'featured', 'non-featured'] })
        : store.getVideosOfSubtree(filter.value, { filter: ['featured-webinar', 'featured', 'non-featured'] })
      ).filter(video => {
        const videoname = getTranslated(video.name) || '';
        const path = store.getPathById(video._id).map(el => getTranslated(el.name)).join(',');
        return regex.test(videoname) ||
          regex.test(path) ||
          video.tags.map(tag => regex.test(`#${tag}`) || regex.test(tag)).includes(true);
      }) as (Recording | Webinar)[]
    });

    const allVideosCount = computed(() => {
      const regex = new RegExp(escapeRegExp(search.value.trim()), 'i');
      return store.getVideos({ filter: ['featured', 'non-featured'] }).filter(video => {
        const videoname = getTranslated(video.name) || '';
        const path = store.getPathById(video._id).map(el => getTranslated(el.name)).join(',');
        return regex.test(videoname) ||
          regex.test(path) ||
          video.tags.map(tag => regex.test(`#${tag}`) || regex.test(tag)).includes(true);
      }).length || ''
    });

    const featuredIds = ref<Set<string>>(new Set());

    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      featuredIds.value = new Set([
        ...store.getVideos({ filter: 'featured-webinar' }).map(webinar => webinar._id),
        ...store.getVideos({ filter: 'featured' }).map(video => video._id),
      ]);
    }, { immediate: true });

    function isFeatured(video: Video) {
      return featuredIds.value.has(video._id);
    }

    ///-------------------------------------------------------------------
    /// HINT
    ///-------------------------------------------------------------------

    function getHintText(video: Video) {
      const hint = getTranslated(video.hint);
      return hint || '';
    }

    ///-------------------------------------------------------------------
    /// RESPONSIVE
    ///-------------------------------------------------------------------

    const isTablet = ref(useMedia(Breakpoint.MIN_MD));
    const isDesktop = ref(useMedia(Breakpoint.MIN_LG));

    ///-------------------------------------------------------------------
    /// CATEGORIES
    ///-------------------------------------------------------------------

    const categories = ref<Video[]>([])
    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      categories.value = store.getTree().filter(el => '_rev' in el) as Video[];
    }, { immediate: true });

    ///-------------------------------------------------------------------
    /// DOM
    ///-------------------------------------------------------------------

    const videoElIds = ref<string[]>([]);
    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      nextTick(() => {
        const ids: string[] = [];
        document.querySelectorAll('[data-video-id]').forEach(el => ids.push(el.getAttribute('data-video-id') as string));
        videoElIds.value = ids;
      })
    }, { immediate: true });

    ///-------------------------------------------------------------------
    /// TAGS
    ///-------------------------------------------------------------------

    const tagsExpanded = ref(new Set<string>());

    const tagsExpandible = ref(new Map<string, boolean>());
    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      tagsExpandible.value = new Map(videoElIds.value.map(id => [id, doTagsWrapFor(id)]))
    });

    function doTagsWrapFor(id: string) {
      const cardEl = document.querySelector(`[data-video-id="${id}"]`) as HTMLElement;
      if (!cardEl) {
        return false;
      }
      const tagsWrapperEl = cardEl.querySelector('.bp-video-area-view__video-tags') as HTMLElement;
      if (!tagsWrapperEl) {
        return false;
      }
      const tagsWrapperComputedStyle = window.getComputedStyle(tagsWrapperEl);
      const padding = parseFloat(tagsWrapperComputedStyle.getPropertyValue('padding-left')) + parseFloat(tagsWrapperComputedStyle.getPropertyValue('padding-right'));
      const availableWidth = tagsWrapperEl.getBoundingClientRect().width - padding;

      const tagEls = tagsWrapperEl.querySelectorAll('.bp-video-area-view__video-tag');
      let width = 0;
      tagEls.forEach(tagEl => width += tagEl.getBoundingClientRect().width);
      return availableWidth < width;
    }

    function updateTagsExpanded() {
      videoElIds.value.forEach(id => tagsExpandible.value.set(id, doTagsWrapFor(id)));
    }
    watch(() => videoElIds.value, updateTagsExpanded, { immediate: true });
    onMounted(() => {
      addEventListener('resize', updateTagsExpanded, { passive: true });
    })
    onUnmounted(() => {
      removeEventListener('resize', updateTagsExpanded);
    })

    function toggleTags(id: string) {
      if (tagsExpanded.value.has(id)) {
        tagsExpanded.value.delete(id);
      } else {
        tagsExpanded.value.add(id);
      }
    }

    function tagExpandIcon(id: string) {
      if (!tagsExpanded.value.has(id)) {
        return {
          icon: 'arrow-up-right-and-arrow-down-left-from-center',
          transform: 'rotate--45'
        }
      } else {
        return {
          icon: 'arrow-down-left-and-arrow-up-right-to-center',
          transform: 'rotate--45'
        }
      }
    }

    ///-------------------------------------------------------------------
    /// TOOLTIP
    ///-------------------------------------------------------------------

    function pathTooltip(el: HTMLSpanElement) {
      return { text: el.scrollWidth > el.clientWidth ? el.innerText : '' };
    }

    function pathKey(folderId: string) {
      return `${folderId}_${window.innerWidth}`
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      allVideosCount,
      base64ToHex,
      categories,
      contentEl,
      currentLanguageISOString,
      doTagsWrapFor,
      filter,
      formatBytes,
      getHintText,
      getTranslated,
      isDesktop,
      isFeatured,
      isTablet,
      localizeDate,
      masonry,
      pathKey,
      pathTooltip,
      search,
      store,
      tagExpandIcon,
      tagsExpanded,
      tagsExpandible,
      toggleTags,
      updateFilter,
      videos,
    }
  }
});

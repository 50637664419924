
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { escapeRegExp } from '@/utils/string';
import { getTranslated } from '@/translation';
import { Tree } from '@/utils/object';
import { Video, useVideoStore, Category } from '@/stores/video';

export default defineComponent({
  name: 'bp-video-menu-item',
  props: {
    data: {
      type: Array as PropType<Tree<Video>[]>,
      default: () => [],
    },
    filter: {
      type: String,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
  },
  emits: [
    'update:filter'
  ],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// FOLDERS
    ///-------------------------------------------------------------------

    const store = useVideoStore(true);
    const categories = computed(() => props.data.filter(dl => dl.doc_type === 'video_category') as Tree<Category>[])
    const open = ref<Set<string>>();

    watch(() => categories.value, folders => {
      if (!open.value && folders.length > 0) {
        open.value = new Set(folders.filter(el => store.hasChildren(el._id, 'category')).map(el => el._id));
      }
    }, { immediate: true });

    function toggle(folderId: string) {
      if (open.value) {
        if (open.value.has(folderId)) {
          open.value.delete(folderId);
        } else {
          open.value.add(folderId);
        }
      }
    }

    function folderIcon(folderId: string) {
      if (open.value && open.value.has(folderId)) {
        return ['far', 'folder-open'];
      }
      return ['far', 'folder'];
    }

    ///-------------------------------------------------------------------
    /// FILTER
    ///-------------------------------------------------------------------

    function updateFilter(folderId: string) {
      ctx.emit('update:filter', folderId);
    }

    ///-------------------------------------------------------------------
    /// FILES
    ///-------------------------------------------------------------------

    function videoCount(category: Category) {
      const regex = new RegExp(escapeRegExp(props.search.trim()), 'i');
      const videos = store.getVideosOfSubtree(category._id).filter(video => {
        const videoname = getTranslated(video.name) || '';
        const path = store.getPathById(video._id).map(el => getTranslated(el.name)).join(',');
        return regex.test(videoname) ||
          regex.test(path) ||
          video.tags.map(tag => regex.test(`#${tag}`) || regex.test(tag)).includes(true);
      });
      return videos.length || '';
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      categories,
      folderIcon,
      getTranslated,
      open,
      store,
      toggle,
      updateFilter,
      videoCount,
    }
  }
});

import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "bp-video-area-view" }
const _hoisted_2 = { class: "bp-video-area-view__search-wrapper" }
const _hoisted_3 = { class: "bp-video-area-view__notification" }
const _hoisted_4 = {
  key: 0,
  class: "bp-video-area-view__view-toggle"
}
const _hoisted_5 = { class: "flex gap-sm" }
const _hoisted_6 = { class: "bp-video-area-view__menu-item-count" }
const _hoisted_7 = { class: "bp-video-area-view__video-header" }
const _hoisted_8 = { class: "bp-video-area-view__video-path" }
const _hoisted_9 = { class: "bp-video-area-view__video-content" }
const _hoisted_10 = {
  key: 0,
  class: "bp-video-area-view__video-tags-wrapper",
  style: {"margin-right":"auto"}
}
const _hoisted_11 = ["title", "onClick"]
const _hoisted_12 = { class: "bp-video-area-view__video-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_toggle_button = _resolveComponent("bp-toggle-button")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_video_menu_item = _resolveComponent("bp-video-menu-item")!
  const _component_bp_video_preview = _resolveComponent("bp-video-preview")!
  const _component_bp_tooltip = _resolveComponent("bp-tooltip")!
  const _component_bp_card = _resolveComponent("bp-card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_bp_view_header, {
      image: "/static/background/app_background_video.jpg",
      icon: ['fad','play-circle']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Video area")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_bp_card, {
      "fill-width": "",
      "fill-height": "",
      loading: _ctx.store.isLoading(),
      tabs: [{_id: 'videos'}],
      "tabs-position": "inside"
    }, {
      "tabs-header": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Receive notifications about new videos/webinars?")
              ])),
              _: 1
            }),
            _createVNode(_component_bp_button, {
              icon: "gears",
              to: {name: 'datamanagement', query: {tab: 'email_notifications'}}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Configure")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_bp_input, {
            class: "bp-video-area-view__search",
            clearable: "",
            large: _ctx.isDesktop,
            placeholder: _ctx.$gettext('Search…'),
            modelValue: _ctx.search,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
          }, null, 8, ["large", "placeholder", "modelValue"]),
          (_ctx.isTablet)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_translate, { class: "bp-video-area-view__view-toggle-heading" }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Toggle view")
                  ])),
                  _: 1
                }),
                _createVNode(_component_bp_toggle_button, {
                  data: [
              // { _id: 'list', icon: 'list', text: $gettext('List layout'), click: () => masonry = false },
              { _id: 'grid', icon: 'grid-2', text: _ctx.$gettext('Grid layout'), click: () => _ctx.masonry = false },
              { _id: 'masonry', icon: 'objects-column', text: _ctx.$gettext('Masonry layout'), click: () => _ctx.masonry = true },
            ]
                }, null, 8, ["data"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      tabs: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(['bp-video-area-view__menu-item', {
          'bp-video-area-view__menu-item--highlighted': _ctx.filter === 'all',
        }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateFilter('all')))
        }, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_bp_icon, {
              class: "pd-md",
              icon: ['far', 'folders']
            }),
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("All")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.allVideosCount), 1)
        ], 2),
        _createVNode(_component_bp_video_menu_item, {
          data: _ctx.categories,
          search: _ctx.search,
          filter: _ctx.filter,
          "onUpdate:filter": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter) = $event))
        }, null, 8, ["data", "search", "filter"])
      ]),
      "tabs-content": _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.masonry ? 'bp-masonry-grid' : 'div'), {
          class: "bp-video-area-view__videos",
          ref: "contentEl"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videos, (video) => {
              return (_openBlock(), _createBlock(_component_bp_card, {
                key: video._id,
                class: _normalizeClass(['bp-video-area-view__video', {
                'bp-video-area-view__video--featured': _ctx.isFeatured(video),
                'bp-video-area-view__video--has-tags': video.tags.length > 0,
              }]),
                "fill-width": "",
                ribbon: 'webinar' in video ? _ctx.$gettext('WEBINAR') : _ctx.isFeatured(video) ? _ctx.$gettext('NEW') : undefined,
                "ribbon-color": 'webinar' in video ? undefined : _ctx.isFeatured(video) ? 'yellow' : undefined,
                "ribbon-icon": 'webinar' in video ? undefined : _ctx.isFeatured(video) ? 'star' : undefined,
                "data-video-id": video._id
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.getPathById(video._id), (folder, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: _ctx.pathKey(folder._id)
                        }, [
                          (index > 0)
                            ? (_openBlock(), _createBlock(_component_bp_icon, {
                                key: 0,
                                icon: "angle-right"
                              }))
                            : _createCommentVNode("", true),
                          _withDirectives((_openBlock(), _createElementBlock("span", null, [
                            _createTextVNode(_toDisplayString(_ctx.getTranslated(folder.name)), 1)
                          ])), [
                            [_directive_tooltip, _ctx.pathTooltip]
                          ])
                        ], 64))
                      }), 128))
                    ]),
                    (_ctx.getTranslated(video.hint))
                      ? _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
                          class: "bp-video-area-view__video-hint-icon",
                          icon: ['fad', 'info-circle'],
                          key: _ctx.currentLanguageISOString()
                        })), [
                          [_directive_tooltip, { offset: [-16, 4], placement: 'top-end', text: _ctx.getHintText(video) }]
                        ])
                      : _createCommentVNode("", true)
                  ])
                ]),
                footer: _withCtx(() => [
                  _createElementVNode("span", _hoisted_12, [
                    (_ctx.store.isFeaturedWebinar(video._id))
                      ? (_openBlock(), _createBlock(_component_bp_tooltip, {
                          key: 0,
                          condition: video._disabled,
                          tooltip: _ctx.$gettext(`You don't have the permission to access the webinar`)
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_bp_button, {
                              color: "green",
                              icon: "arrow-right-to-bracket",
                              disabled: video._disabled,
                              to: { name: 'video.play', params: {
                      slug: video.slug,
                    }}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_translate, null, {
                                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                                    _createTextVNode("Participate")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1032, ["disabled", "to"])
                          ]),
                          _: 2
                        }, 1032, ["condition", "tooltip"]))
                      : (_openBlock(), _createBlock(_component_bp_tooltip, {
                          key: 1,
                          condition: video._disabled,
                          tooltip: _ctx.$gettext(`You don't have a valid license to watch this video`)
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_bp_button, {
                              color: "green",
                              icon: "play",
                              disabled: video._disabled,
                              to: { name: 'video.play', params: {
                      slug: video.slug,
                    }}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_translate, null, {
                                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                                    _createTextVNode("Play video")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1032, ["disabled", "to"])
                          ]),
                          _: 2
                        }, 1032, ["condition", "tooltip"]))
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", {
                      class: "bp-video-area-view__video-thumbnail",
                      style: _normalizeStyle(`--youtube-url: url('https://img.youtube.com/vi/${video.youtube_id}/0.jpg')`)
                    }, null, 4),
                    _createVNode(_component_bp_video_preview, {
                      "hide-webinar-status": "",
                      "hide-icon": "",
                      "hide-button": "",
                      "model-value": video
                    }, null, 8, ["model-value"]),
                    (video.tags.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createElementVNode("div", {
                            class: _normalizeClass(['bp-video-area-view__video-tags', {
                    'bp-video-area-view__video-tags--expanded': _ctx.tagsExpanded.has(video._id),
                  }])
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(video.tags.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1), (tag) => {
                              return (_openBlock(), _createElementBlock("span", {
                                key: tag,
                                class: "bp-video-area-view__video-tag",
                                title: _ctx.$gettext('Search for videos with the tag #%{tag}', { tag: tag.toLowerCase() }),
                                onClick: ($event: any) => (_ctx.search = `#${tag.toLowerCase()}`)
                              }, [
                                _createVNode(_component_bp_icon, {
                                  class: "pr-xs",
                                  icon: "hashtag"
                                }),
                                _createElementVNode("span", null, _toDisplayString(tag.toLowerCase()), 1)
                              ], 8, _hoisted_11))
                            }), 128))
                          ], 2),
                          (_ctx.tagsExpandible.get(video._id))
                            ? (_openBlock(), _createBlock(_component_bp_icon, {
                                key: 0,
                                class: "bp-video-area-view__video-tags-expand-collapse-icon",
                                icon: _ctx.tagExpandIcon(video._id),
                                onClick: ($event: any) => (_ctx.toggleTags(video._id))
                              }, null, 8, ["icon", "onClick"]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 2
              }, 1032, ["class", "ribbon", "ribbon-color", "ribbon-icon", "data-video-id"]))
            }), 128))
          ]),
          _: 1
        }, 512))
      ]),
      _: 1
    }, 8, ["loading"])
  ]))
}